<template>
  <div class="policy-page">
    <v-row justify="center" align="center">
      <v-col>
        <div class="text-center">
          <vuetify-logo />
        </div>

        <div class="card">
          <img class="thank-img" src="../assets/images/Delivery.png" />
          <p class="done">تم إستلام طلبكم</p>
          <p>
            تم استلام الطلب , رقم الطلب هو:
            <span dir="ltr" style="color: #0b81bb; font-weight: bold">{{
              orderId
            }}</span>
          </p>

          <p>يستغرق توصيل الطلب من 24 - 48 ساعة حسب المنطقة</p>
          <!-- <a class="btn complete-btn">اكمال الطلب</a> -->
        </div>
        <router-link to="/" class="btn home-btn"> الصفحه الرئيسية </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import { ServiceFactory } from "../services/ServiceFactory";
//const aboutService = ServiceFactory.get("about");
import { API_URL } from "../config";
import VuetifyLogo from "../components/VuetifyLogo.vue";
export default {
  data: () => ({
    orderId: null,
  }),
  components: {
    VuetifyLogo,
  },
  created() {
    this.fetchOrder();

    console.log(this.$route.params.orderId);
  },
  methods: {
    async fetchOrder() {
      this.dataLoading = true;
      this.$http
        .get(`${API_URL}/checkout/getOrderId/` + this.$route.params.orderId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.orderId = response.data.data.order_id;
          }
        });
      this.dataLoading = false;
    },
  },
};
</script>

<style>
.policy-page {
  text-align: center;
}

.policy-page .card {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 30px;
}
.thank-img {
  width: 160px;
}
.policy-page .card p {
  font-size: 15px !important;
  color: #5a5a5a;
  font-weight: normal;
  /* font-family: "Roboto", sans-serif !important; */
}
p.done {
  font-weight: 700 !important;
}
.home-btn {
  display: block !important;
  text-align: center !important;
  background-color: #4c9e53 !important;
  color: #fff !important;
  border-radius: 37px !important;
  padding: 9px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  width: 100%;
  border: none !important;
  font-family: "frutiger lt arabic 55 roman" !important;
}
</style>
